/*** 
=============================================
    Appoinment Area style
=============================================
***/
.appoinment-area {
    padding: 80px 0 0px;
}

.appoinment-area .inner-content {
    /* position: relative; */
    /* overflow: hidden; */
}

.appoinment-area .appoinment {
    display: block;
    background: #ffffff;
    padding: 40px 40px 40px;
    margin-bottom: 80px;
}

.appoinment-area .appoinment .sec-title {
    padding-bottom: 22px;
    text-align: left;
}

.appoinment-area .appoinment .sec-title p {
    margin: 25px 0 0;
}

.appoinment-area .appoinment .appoinment-form .field-input {
    position: relative;
    border: 1px solid #d9d9d9;
    background: #ffffff;
    border-radius: 6px;
}

.appoinment-area .appoinment .appoinment-form .field-input input[type=text] {
    width: 100%;
    border: 1px solid #ebf8ff;
    height: 50px;
    display: block;
    padding: 0 15px;
    color: #333;
    font-size: 14px;
    font-weight: 400;
    transition: all 500ms ease;
}

.appoinment-area .appoinment .appoinment-form button {
    width: 100%;
    transition: all 500ms ease;
    border: none;
    background-color: var(---color01);
    color: white;
    padding: 6px 15px;
    border-radius: 3px;
    box-shadow: 0 0 5px var(---color01);
}

.appoinment-area .appoinment .appoinment-form .field-input input[type=text]:focus {
    border-color: #209fd6;
}

.appoinment-area .appoinment .appoinment-form button:hover {
    background: #0483ba;
}

.appoinment-area .appoinment .appoinment-form .field-input .icon-box {
    position: absolute;
    right: 15px;
    top: 0;
    color: #209fd6;
    font-size: 14px;
    margin: 12px 0;
}

#ui-datepicker-div.ui-widget-content {
    border: 1px solid #c5c5c5;
    background: #fff none repeat scroll 0 0;
    border: 1px solid #777;
    color: #252525;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    border-radius: 0px;
    width: 270px;
    padding: 5px;
}

#ui-datepicker-div.ui-widget-content .ui-state-default {
    border: 1px solid #d3d3d3;
    /* background: #e6e6e6 url(images/ui-bg_glass_75_e6e6e6_1x400.png) 50% 50% repeat-x; */
    font-weight: normal;
    color: #555555;
    background-image: none;
    background-color: #ffffff;
    text-align: center;
    display: block;
    padding: 3px;
}

#ui-datepicker-div.ui-datepicker .ui-datepicker-header {
    background: #43c3ea none repeat scroll 0 0;
    transition: .5s ease;
    background: rgba(40, 169, 229, 1);
    background: -moz-linear-gradient(top, rgba(40, 169, 229, 1) 0%, rgba(44, 81, 168, 0.8) 100%);
    background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(40, 169, 229, 1)), color-stop(100%, rgba(44, 81, 168, 0.8)));
    background: -webkit-linear-gradient(top, rgba(40, 169, 229, 1) 0%, rgba(44, 81, 168, 0.8) 100%);
    background: -o-linear-gradient(top, rgba(40, 169, 229, 1) 0%, rgba(44, 81, 168, 0.8) 100%);
    background: -ms-linear-gradient(top, rgba(40, 169, 229, 1) 0%, rgba(44, 81, 168, 0.8) 100%);
    background: linear-gradient(to bottom, rgba(40, 169, 229, 1) 0%, rgba(44, 81, 168, 0.8) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#28a9e5', endColorstr='#2c51a8', GradientType=0);

    border: 2px solid #252525;
    border-radius: 0;
    color: #fff;
    font-weight: 700;
    padding: 5px 0;
    position: relative;
}

.ui-datepicker .ui-datepicker-prev {
    left: 5px;
}

.ui-datepicker .ui-datepicker-prev,
.ui-datepicker .ui-datepicker-next {
    border-radius: 30%;
    height: 20px;
    position: absolute;
    top: 7px;
    width: 20px;
    transition: all 500ms ease;
    cursor: pointer;
}

.ui-datepicker .ui-datepicker-next {
    right: 5px;
}

.ui-datepicker .ui-datepicker-prev:hover,
.ui-datepicker .ui-datepicker-next:hover {
    top: 7px;
    background: #fff;
    border: none;
}

.ui-datepicker table {
    border-collapse: collapse;
    font-size: 13px;
    margin: 0 0 0.4em;
    width: 100%;
}

.ui-datepicker th {
    border: medium none;
    font-weight: 600;
    padding: 2px 3px;
    text-align: center;
}

.ui-datepicker-calendar .ui-state-default:hover,
.ui-datepicker-calendar .ui-state-default:active {
    background: #43c3ea;
    color: #fff;
    border-color: #43c3ea;
}

.appoinment-area .inner-content .img-holder {
    /* position: absolute;
    left: 0;
    bottom: 0px; */
    /* width: 100%;
    height: 100%; */
}

.appoinment-area {
    background-image: url("../../Images/Home/Form\ Back.jpg");
    background-size: cover;
}

.appoinment-area .img-holder {
    height: auto;
    width: 100%;
}

.appoinment-area .img-holder img {
    height: 100%;
    width: 100%;
}

/* .ant-picker-outlined {
    width: 100%;
    height: 50px;
}

.ant-select-selector{
    height: 50px !important;
} */

@media(max-width:430px){
    .appoinment-area .inner-content .img-holder {
        display: none;
    }
}