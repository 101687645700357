/*** 
=============================================
    Fact counter Area style
=============================================
***/
.fact-counter-area {
    background-attachment: fixed;
    background-position: center top;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 80px;
    padding-bottom: 50px;
    position: relative;
    z-index: 1;
}

.fact-counter-area::before {
    transition: .5s ease;
    background: rgba(40, 169, 229, 1);
    background: -moz-linear-gradient(top, rgba(40, 169, 229, 1) 0%, rgba(44, 81, 168, 0.8) 100%);
    background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(40, 169, 229, 1)), color-stop(100%, rgba(44, 81, 168, 0.8)));
    background: -webkit-linear-gradient(top, rgba(40, 169, 229, 1) 0%, rgba(44, 81, 168, 0.8) 100%);
    background: -o-linear-gradient(top, rgba(40, 169, 229, 1) 0%, rgba(44, 81, 168, 0.8) 100%);
    background: -ms-linear-gradient(top, rgba(40, 169, 229, 1) 0%, rgba(44, 81, 168, 0.8) 100%);
    background: linear-gradient(to bottom, rgba(40, 169, 229, 1) 0%, rgba(44, 81, 168, 0.8) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#28a9e5', endColorstr='#2c51a8', GradientType=0);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
}

.fact-counter-area .sec-title {
    padding-bottom: 40px;
}

.fact-counter-area .sec-title h1 {
    color: #ffffff;
    margin: 0 0 14px;
}

.fact-counter-area .sec-title p {
    color: #ebf8ff;
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    margin: 0;
}

.fact-counter-area ul li {
    display: inline-block;
    float: left;
    border-right: 1px solid rgba(255, 255, 255, 0.08);
    width: 292px;
    margin-bottom: 30px;
}

.fact-counter-area ul li:last-child {
    border-right: none;
}

.fact-counter-area .single-item {
    width: 100%;
    display: block;
    padding: 0px 25px 0px;
    transition: all 500ms ease;
    position: relative;
}

.fact-counter-area .single-item .icon-holder {
    margin: 0 0 16px;
}

.fact-counter-area .single-item .icon-holder span:before {
    color: #ffffff;
    font-size: 65px;
    line-height: 65px;
}

.fact-counter-area .single-item h1 span {
    color: #ffffff;
    font-size: 30px;
    font-weight: 400;
    font-family: 'Montserrat', sans-serif;
}

.fact-counter-area .single-item h3 {
    color: #ffffff;
    font-size: 18px;
    line-height: 26px;
    font-weight: 400;
    text-transform: capitalize;
    font-family: 'Open Sans', sans-serif;
    margin: 11px 0 0;
}

.icon-holder {
    display: flex;
    justify-content: center;
    align-items: center;
}

.flaticon-medical-3 {
    /* border: 1px solid red; */
    height: 50px;
    width: 50px;
}

.flaticon-medical-3 img {
    height: 100%;
    width: 100%;
}

@media(max-width:768px) {
    .icon-holder {
        justify-content: center;
    }

    .about-us-area .right-content .single-item .text p {
        font-size: 13px;
    }

    .about-us-area .right-content .single-item .text h3 {
        font-size: 20px;
    }

    .fact-counter-area .single-item {
        margin-bottom: 30px;
    }
}