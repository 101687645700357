.ProductList-Section-1 {
    background: url("../Images/Banner/banner-02.avif");
    -webkit-background-size: cover;
    height: 80vh;
}

.ProductList-Section-con {
    align-items: center;
    display: flex;
    height: 85vh;
    justify-content: space-between;
}

.ProductList-Section-Text {
    margin: 0 0 0 30px;
}

.ProductList-Section-Text h2 {
    color: #fff;
    font-family: var(---MainHeadFont);
    font-size: 30px;
    font-weight: 500;
    letter-spacing: 1px;
    text-align: left;
    text-transform: capitalize;
}

.ProductList-Section-NavPage {
    align-items: center;
    display: flex;
    font-family: var(---SmallFont);
}

.ProductList-Section-NavPage span {
    color: #fff;
    font-family: var(---Font1);
}

.ProductList-Section-NavPage span svg {
    font-size: 17px;
    margin: 0 4px 5px 0;
}

.ProductList-Section-NavPage h3 svg {
    color: #fff;
}

.ProductList-Section-NavPage h2 {
    color: #fff;
    font-family: var(---Font1);
    font-size: 16px;
    font-weight: 400;
    margin: 0;
}


/* //////////////////////////////////////// */

.Contact-Page-Form-And-Details-con {
    /* border: 1px solid red; */

}

.Contact-Page-Details-con {
    /* border: 1px solid blue; */
    background-color: white;
    border-radius: 8px;
    margin: 10px 0px;
    padding: 20px;
}

.Contact-Page-Details-Info-con {
    /* border: 1px solid blue; */
    width: 90%;
}

.Contact-Page-Details-Head-con {
    /* border: 1px solid black; */
    text-align: left;
}

.Contact-Page-Details-Head-con h3 {
    font-size: 28px;
    font-family: var(---font01);
    font-weight: 600;
    color: black;
}

.Contact-Page-Details-Head-con p {
    font-size: 14px;
    color: gray;
}

.Contact-Page-Details-contact-con {
    margin: 20px 0px;
}

.Contact-Page-Details-Icons-Info-con {
    display: flex;
    align-items: center;
    padding: 10px 0px;
    border-bottom: 1px solid gray;
}

.Contact-Page-Details-Icons-Info-con svg {
    font-size: 25px;
}

.Contact-Page-Details-Info {
    text-align: left;
    margin: 0px 0px 0px 20px;
}

.Contact-Page-Details-Info h4 {
    font-size: 15px;
    font-family: var(---font01);
}

.Contact-Page-Details-Info h5 {
    font-size: 13px;
    font-family: var(---font01);
    color: black;
}

.Contact-Page-Details-Info h5 a {
    text-decoration: none;
    color: black;

}

/* /////////////////// */

.Contact-page-Form-Con {
    /* border: 1px solid red; */
    padding: 20px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 0 2px gray;
}

.Contact-page-Form-Head-Con {
    /* border: 1px solid blue; */
}

.Contact-page-Form-Head-Con h2 {
    font-size: 25px;
    font-family: var(---font01);
    font-weight: 600;
    color: black;
}

.Contact-Page-Form-Inputs {
    text-align: left;
    margin: 15px 0px 0px 0px;
}

.Contact-Page-Form-Inputs label {
    font-size: 13px;
    font-family: var(---font01);
    font-weight: 600;
    margin: 8px 0px 8px 0px;
}

.Like-to-pay-form-con-Card-Info-Input textarea {
    margin: 0px 10px 0px 0px;
    padding: 6px;
    color: black;
    border-radius: 8px;
    color: black;
    border: none;
    width: 100%;
    height: 20vh;
}

.Contact-Page-Form-button {
    margin: 20px 0px;
}

.Contact-Page-Form-button button {
    border: none;
    width: 100%;
    padding: 10px 0px;
    color: white;
    background-color: var(---color01);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 8vh;
    margin: 20px 0px 0px 0px;
}


.Contact-Page-Map-Section {
    /* border: 1px solid red; */
    padding: 20px 0px;
}

.Contact-Page-Map-Section-con {
    /* border: 1px solid blue; */
}

.Contact-Page-Map-Head-Section-con {
    /* border: 1px solid green; */
}

.Contact-Page-Map-Head-Section-con h2 {
    font-size: 28px;
    font-family: var(---font01);
    font-weight: 600;
    color: black;
}

.Contact-Page-Map-Section-con-frem {
    /* border: 1px solid black; */
    margin: 20px 0px;
    border-radius: 10px;
    height: 60vh;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 0 2px gray;
}

.Like-to-pay-form-con-Card-Info-Input input {
    margin: 0px 10px 0px 0px;
    padding: 6px;
    color: black;
    border-radius: 8px;
    color: black;
    border: none;
    width: 100%;
    border: 1px solid gray;
}

.Like-to-pay-form-con-Card-Info-Input textarea {
    border: 1px solid gray;
}

@media(max-width:576px){
    .ProductList-Section-1 {
        height: 55vh;
    }
    .ProductList-Section-con {
        align-items: center;
        display: flex;
        height: 60vh;
    }
    .Contact-Page-Details-con {
        margin: 30px 0px;
        padding: 0px;
    }
}