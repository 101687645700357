/*** 
=============================================
    Welcome Area style
=============================================
***/

.welcome-area {
    background: #f3fbff;
    padding: 80px 0 40px;
}

.sec-title h2 {
    font-family: var(---font01);
    padding-bottom: 10px;
    position: relative;
    display: inline-block;
    margin-bottom: 45px;
}

.Head-Style::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 40%;
    right: 0;
    height: 2px;
    background-color: var(---color01);
    width: 70px;
}

.welcome-area .single-item {
    margin-bottom: 40px;
    position: relative;
    display: block;
    max-width: 100%;
    background-color: #f3fbff;
    color: #fff;
    overflow: hidden;
    -webkit-box-shadow: 0px 1px 10px 1px rgba(223, 228, 231, 1);
    -moz-box-shadow: 0px 1px 10px 1px rgba(223, 228, 231, 1);
    box-shadow: 0px 1px 10px 1px rgba(223, 228, 231, 1);
    padding: 30px 20px 23px;
    min-height: 50vh;
}

.welcome-area .single-item .icon-holder {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px 0px 20px 0px;
}

.welcome-area .single-item .icon-holder span:before {

    font-size: 60px;
    line-height: 60px;
    text-align: center;
    display: block;
    margin: 0 0 25px;
    font-weight: 500;
}

.welcome-area .single-item .text-holder h3 {
    text-transform: capitalize;
    margin: 0 0 15px;
    font-family: var(---font01);
    color: black;
    font-size: 20px;
    font-weight: 400;
}

.welcome-area .single-item .text-holder p {
    color: #949494;
    font-size: 13px;
    line-height: 20px;
    margin: 0;
    text-align: justify;
}

.flaticon-medical-6 {
    /* border: 1px solid blue; */
    height: 50px;
    width: 50px;
}

.flaticon-medical-6 img {
    height: 100%;
    width: 100%;
}