/* /////////////////////////////////////////////////////////// */


.home-about-section-con {
    padding: 10vh 0px;
}


.about-section-image-details {
    /* border: 1px solid red; */
}

.about-page-section-image-con {
    /* border: 1px solid blue; */
    height: 80vh;
}

.about-page-section-image-con img {
    height: 100%;
    width: 100%;
}

.about-section-page-about-details-con {
    height: 100%;
    display: flex;
    align-items: center;
    border-left: none;
}

.about-section-page-about-details {
    padding: 40px 40px 40px;
    border: 1px solid black;
    width: 100%;
    border-left: none;
}

.about-section-page-about-info {
    text-align: left;
}

.about-section-page-about-info h3 {
    font-weight: 300;
    color: #1b1b1b;
    letter-spacing: .05em;
    font-family: var(---font01);
    text-transform: uppercase;
}

.about-section-page-about-info p {
    font-family: var(---font01);
    font-weight: 300;
}

.About-section-page-plus-btn {}


.home-section-svg-overlay-btn-con {
    display: inline-block;
}

.home-section-overlay-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: 1px solid red; */
    padding: 8px;
    border-radius: 50px;
    background-color: #363636;
}

.home-section-overlay-btn svg {
    font-size: 20px;
    color: white;
    transition: all 0.4s;
}

.home-section-overlay-btn:hover svg {
    transform: rotate(-90deg)
}

.about-section-1-overlay-section {
    /* border: 1px solid blue; */
    transform: translate(0px, -180px);
}

.about-us-one-left {
    /* border: 1px solid blue; */
    height: 100%;
}

.about-us-one-left img{
    height: 100%;
    width: 100%;
}

@keyframes movebounce {
    0% {
        -webkit-transform: translateY(0px);
        transform: translateY(0px);
    }

    50% {
        -webkit-transform: translateY(20px);
        transform: translateY(20px);
    }

    100% {
        -webkit-transform: translateY(0px);
        transform: translateY(0px);
    }
}

.hero-shape .shape9,
.hero-shape .shape7,
.shape2,
.shape.app3,
.about-animate,
.a3,
.a4,
.breadcrumb7,
.promo3,
.ab1 {
    animation: movebounce 3s infinite linear;
}

@media(max-width:1024px) {
    .about-section-page-about-details {
        padding: 50px 50px 50px;
    }
}

@media(max-width:768px) {

    .about-section-page-about-details-con {
        margin-top: 30px;
    }

    .about-section-page-about-details {
        border-left: 1px solid black;
    }
}

@media(max-width:576px){
    .about-section-image-details .col-md-6 {
        padding: 0px 15px !important;
    }
    .about-section-page-about-details {
        padding: 30px 30px 30px;
    }
}