.Home-section-Banner-con {
    /* border: 1px solid red; */
}


.Home-Banner-Image-con {
    /* border: 1px solid blue; */
    height: 100vh;
    background-image: url("../Images/Banner/banner\ Image\ 01.avif");
    background-size: cover;
    display: flex;
    align-items: center;
}

.Home-Banner-Text-Con {
    /* border: 1px solid blue; */
    width: 30%;
    margin: 0px 0px 0px 50px;
    text-align: left;
}

.Home-Banner-Text-details {
    /* border: 1px solid red; */
}


.Home-Banner-Text-details h2 {
    font-family: var(---font01);
    color: var(---color01);
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 1px;
    /* margin: 0px; */
}

.Home-Banner-Text-details h1 {
    font-family: var(---font01);
}

.Home-Banner-Text-details p {
    font-family: var(---font01);
    font-size: 14px;
}

.Home-Banner-btn-con {
    /* display: flex;
    justify-content: space-between;
    align-items: center; */
}

.Home-Banner-btn-con .ant-btn{
    height: 40px;
}

.banner-booking-form button {
    border: none;
    background-color: rgba(255, 255, 255, 0.662) !important;
    color: var(---color01) !important;
    padding: 6px 15px;
    border-radius: 3px;
    box-shadow: 0 0 5px var(---color01);
}

.Home-Banner-btn-con a {}

.Home-Banner-btn-con button {
    border: none;
    background-color: var(---color01);
    color: white;
    padding: 8px 25px 8px 25px;
    border-radius: 50px;
    box-shadow: 0 0 5px var(---color01);
    font-family: var(---font01);
}

.banner-booking-form{
    margin-top: 20px;
}

@media(max-width:768px){
    .Home-Banner-Text-Con {
        width: 60%;
    }
}

@media(max-width:430px){
    .Home-Banner-Image-con {
        height: 55vh;
    }
    .Home-Banner-Text-Con {
        width: 100%;
        margin: 50px 0px 0px 20px;
        text-align: left;
    }
    .Home-Banner-Text-details h1 {
        font-family: var(---font01);
        font-size: 20px;
    }
    .Home-Banner-btn-con button {
        font-size: 13px;
    }
    .Home-Banner-Text-details p {
        font-family: var(---font01);
        font-size: 12px;
    }
}