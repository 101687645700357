.Our-Vision--Mission-con {
    /* border: 1px solid blue; */
    padding: 10vh 0px;
}

.Our-Vision-Image-con {
    /* border: 1px solid red; */
    height: 30vh;
}

.Our-Vision-Image-con img {
    height: 100%;
    width: 100%;
}

.Our-Vision-Details-con {
    text-align: left;
}

.Our-Vision-Details-con h2 {
    font-family: var(---font01);
    font-size: 25px;
}

.Our-Vision-Details-con p {
    font-family: var(---font01);
}

@media(max-width:768px){
    .Our-Vision-Image-con {
        height: 45vh;
        width: 70%;
        margin-bottom: 10px;
    }
    .Our-Vision-Details-con {
        text-align: left;
        margin-bottom: 40px;
    }
}

@media(max-width:430px){
    .Our-Vision-Image-con {
        height: auto;
    }
}