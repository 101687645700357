/* ///////////////////////////////////////////// Section 1 ////////////////////////////////////////////////// */
.GallerySection1 {
    /* position: absolute;
      top: 0;
      left: 0;
      right: 0;
      z-index: -1; */
    background: #bababa;
}

.Gallery-banner {
    /* background: linear-gradient(90deg, rgba(0, 0, 0, .3), rgba(0, 0, 0, .4)), url("../Image/Banner07.jpg"); */
    /* background-color: gray; */
    /* border: 1px solid red; */
    height: 100vh;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.Gallery-banner-text {
    position: relative;
    height: 80vh;
    justify-content: center;
    display: flex;
    align-items: center;
}

.Gallery-banner-text h1 {
    font-size: 50px;
    font-family: 'Lora', serif;
    font-weight: bold;
    color: rgb(255, 255, 255);
    margin: 0px;
    padding: 0px;

}

.Text {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 100%;

    width: 100%;
    clip-path: polygon(0 0, 100% 100%, 0% 100%);
}

.HB-Text {
    width: 100%;
    text-align: left;
    margin-left: 20vh;
}

.HB-Text p {
    /* color: white; */
    font-size: 30px;
    font-family: 'Courgette', cursive;
    color: white;
}

.gallery-contnet-para {
    display: flex;
    justify-content: center;
    align-items: center;
}

.gallery-contnet-para p {
    width: 60%;
    font-family: var(---font01);
    font-size: 15px;
}


/* //////////////////////////////////////////////////////////////////////////////////////////////////////////////// */

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

.wrapper {
    max-width: 1350px;
    padding: 0px 60px;
    margin: 50px auto;
    display: flex;
    flex-wrap: wrap;
    gap: 2em;
}

.wrapper img {
    cursor: pointer;
}

/* modal */
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, rgba(0, 0, 0, .5), rgba(0, 0, 0, .6));
    display: flex;
    align-items: center;
}

.overlay img {
    display: block;
    max-width: 60%;
    max-height: 80%;
    margin: 60px auto;
    box-shadow: 3px 5px 7px rgba(0, 0, 0, 0.5);
}

.overlay>span {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 30px;
    color: #ffffff;
    z-index: 999;
    cursor: pointer;
}

.overlay-arrows_left {
    display: flex;
    justify-content: space-between;
    position: absolute;
    top: 45%;
    width: 50px;
    height: 50px;
    z-index: 999;
}

.overlay-arrows_left svg {
    width: 50px;
    height: 50px;
}

.overlay-arrows_right svg {
    width: 50px;
    height: 50px;
}

.overlay-arrows_right {
    display: flex;
    justify-content: space-between;
    position: absolute;
    right: 0px;
    width: 50px;
    height: 50px;
    top: 45%;
    z-index: 999;
}


/* /////////////////////////// Section 2 /////////////////////////////////////////// */

.Image-Gallery {
    margin-top: 5vh;
}

.Image-Gallery-title {
    position: relative;
    margin-top: 10vh;
    margin-bottom: 10vh;

}

.Image-Gallery-title h4 {
    font-family: var(---font01);
    font-size: 35px;
    font-weight: 400;
}

.Image-Gallery-title h3 {
    position: absolute;
    font-weight: bold;
    top: -51px;
    left: 81px;
    right: 0;
    font-family: 'Qwitcher Grypen', cursive;
    font-size: 85px;
    color: lightgrey;
    z-index: -1;
}

.ImageG-title {
    display: inline-block;
    background: #dee2e6;
    border-radius: 35px;
    margin-bottom: 15px;
}

.ImageG-title:hover {
    cursor: pointer;
}

.ImageG-title h4 {
    padding: 5px 15px 0px 15px;
}

.G-Images {
    margin: 20px 10px 20px 10px;
    height: 40vh;
    box-shadow: 0 0 5px #808080;
    border-radius: 5px;
}

.G-Images img {
    height: 100%;
    width: 100%;
    border-radius: 5px;
}

@media(max-width:770px) {
    .Gallery-banner {
        /* background: linear-gradient(90deg, rgba(0, 0, 0, .3), rgba(0, 0, 0, .4)), url("../Image/Banner07-res.jpg"); */
        /* background-color: gray; */
        /* border: 1px solid red; */
        height: 100vh;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
    }
}

@media(max-width:576px) {
    .gallery-contnet-para p {
        width: 100%;
    }
}