/** service section **/

.service-section {
    position: relative;
    padding: 102px 90px 110px 90px;
}

.service-section .section-title h2 {
    margin-bottom: 10px;
}

.service-section .offers-content p {
    margin-bottom: 20px;
}

.service-section .btn-one {
    padding: 10px 28px 10px 27px;
    margin-top: 35px;
}

.service-section .icon-area {
    position: relative;
    margin-left: 70px;
    margin-top: -59px;
}

.service-section .icon-area .icon-box {
    margin-bottom: 26px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.icon-box .Services-Image {
    height: 40px;
    width: 40px;
}

.icon-box .Services-Image img {
    height: 100%;
    width: 100%;
}

.service-section .icon-area .single-item {
    position: relative;
    padding: 78px 0px 44px 0px;
    margin: 0px 8px 0px 7px;
    border-bottom: 1px solid #eeeeee;
    background-color: transparent;
    transition: all 500ms ease;
}

.service-section .icon-area .single-item h5 {
    font-family: var(---font01);
    font-size: 17px;
}

.service-section .icon-area .single-item h5 a {
    text-decoration: none;
    color: black;
}

.service-section .service-colmun:nth-child(7) .single-item,
.service-section .service-colmun:nth-child(8) .single-item,
.service-section .service-colmun:nth-child(9) .single-item,
.service-section .service-colmun:nth-child(7) .single-item:hover,
.service-section .service-colmun:nth-child(8) .single-item:hover,
.service-section .service-colmun:nth-child(9) .single-item:hover {
    border-bottom: 1px solid transparent;
}

.service-section .icon-area .single-item:hover {
    background: #f8f8f8;
    transition: all 500ms ease;
    border-bottom: 1px solid #f8f8f8;
}

.service-section .icon-area .single-item:hover .icon-box i:before {
    color: #f6a129;
    transition: all 500ms ease;
}

/* left content */

.offers-content {
    /* border: 1px solid blue; */
    text-align: left;
}

.section-title {}

.section-title h2 {
    margin-bottom: 20px;
    font-family: var(---font01);
    position: relative;
    padding-bottom: 10px;
}

.section-title h2 span {
    color: var(---color01);
}

.Head-Style-Service::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 2px;
    background-color: var(---color01);
    width: 70px;
}

.offers-content .text p {
    font-family: var(---font01);
    font-size: 13px;
}

.offers-content .button {
    /* border: 1px solid blue; */
}

.offers-content .button a button {
    border: none;
    background-color: var(---color01);
    color: white;
    padding: 6px 15px;
    border-radius: 3px;
    box-shadow: 0 0 5px var(---color01);
}

@media(max-width:768px) {
    .service-section .icon-area {
        margin-top: 30px;
        margin-left: 0px;
    }

    .service-section {
        position: relative;
        padding: 40px 30px 40px 30px;
    }

    .service-section .icon-area .single-item {
        justify-content: left;
    }
}

@media(max-width:430px) {
    .service-section {
        position: relative;
        padding: 40px 30px 40px 30px;
    }

    .service-section .icon-area .icon-box {
        margin-bottom: 0px;
    }

    .service-section .icon-area .single-item {
        position: relative;
        padding: 15px 0px 15px 0px;
        margin: 0px 8px 0px 7px;
        border-bottom: 1px solid #eeeeee;
        background-color: transparent;
        transition: all 500ms ease;
        justify-content: left;
    }

    .service-section .icon-area .single-item h5 {
        font-family: var(---font01);
        font-size: 15px;
        margin: 0px 0px 0px 15px;
    }

}