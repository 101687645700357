.navbar {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 100;
    /* background-color: whitesmoke; */
    border-bottom: 1px solid rgb(222, 222, 222);
    /* backdrop-filter: brightness(0.5); */
}

.Logo-Con {
    height: 10vh;
    border: 2px solid var(---Color01);
    /* border-style: dashed; */
    z-index: 100;
}

.Logo-Con img {
    height: 100%;
    width: 100%;
}

/* ///////////// */

.bg-body-tertiary {
    padding: 0px !important;
}

.navbar-expand-lg .navbar-collapse {
    /* display: flex !important;
    flex-basis: auto;
    justify-content: space-between !important;
    align-items: center; */
}

.navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
    /* border: 1px solid red; */
    width: 100%;
    display: flex;
    justify-content: center;
}

.navbar-expand-lg .navbar-nav .nav-link {
    margin: 0px 10px;
}

/* ///////////////////// */

.Login-Btn {}

.Login-Btn button {
    border: none;
    padding: 6px 30px;
    font-size: 14px;
    border-radius: 50px;
    color: white;
    background: var(---Color02);
}


.navbar-expand-lg .navbar-nav .nav-link a {
    text-decoration: none;
    /* color: var(---color01); */
    color: black;
    font-size: 16px;
    font-weight: 500;
    text-transform: uppercase;
    font-family: var(---Font03);
}

.navbar-expand-lg .navbar-nav .nav-link {
    color: black !important;
    /* color: var(---color01) !important; */
    font-size: 16px;
    font-weight: 500;
    text-transform: uppercase;
    font-family: var(---font01);
}

.dropdown-menu a {
    color: var(---color01) !important;
}

.dropdown-menu a a {
    text-decoration: none;
    color: black !important;
}

.social-media-links-con {
    /* border: 1px solid blue; */
}

.social-media-links {
    display: flex;
    align-items: center;
    justify-content: center;
}

.social-media-links a {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    margin: 0px 6px;
    background-color: var(---color01);
    border-radius: 50px;
    transition: all .5s ease-in-out;
    border: 1px solid var(---color01);
}

.social-media-links a svg {
    font-size: 20px;
    color: white;
    transition: all .5s ease-in-out;
}

.social-media-links a:hover {
    background-color: white;
    border: 1px solid var(---color01);
}

.social-media-links a:hover svg {
    color: var(---color01);
}

.dropdown-menu {
    border-radius: 0px !important;
    transition: 0.5s all ease-in-out;
}

.dropdown-menu .dropdown-item {
    border-bottom: 1px solid rgb(229, 229, 229) !important;
}


@media(max-width:992px) {

    .navbar-expand-lg .navbar-nav-scroll {
        overflow: auto;
        width: auto;
        display: unset;
        justify-content: center;
        text-align: right;
    }

    .Login {
        display: none !important;
    }

    .navbar-toggler {
        background-color: white !important;
    }

    .navbar-expand-lg .navbar-nav .nav-link {
        color: white !important;
    }

    .navbar-expand-lg .navbar-nav .nav-link a {
        color: white !important;
    }
}


@media(max-width:430px) {
    .Logo-Con {
        height: 8vh;
    }
}

@media(max-width:992px) {
    #responsive-navbar-nav {
        background-color: rgba(0, 0, 0, 0.372);
    }

    .social-media-links-con {
        display: none;
    }
}