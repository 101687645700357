/*** 
=============================================
    About Us Area style
=============================================
***/
.about-us-area {
    background: #f7f7f7;
    padding: 80px 0 50px;
}

.about-us-area .left-content {}

.about-us-area .left-content .single-item {
    overflow: hidden;
    margin-bottom: 30px;
}

.about-us-area .left-content .single-item img {
    width: 100%;
}

.about-us-area .left-content .text {
    overflow: hidden;
}

.about-us-area .left-content .text .button {
    overflow: hidden;
    padding-top: 30px;
}

.about-us-area .left-content .text .button a {
    display: inline-block;
    float: left;
}

.about-us-area .left-content .text .button a:hover {
    background: #0487bf;
    color: #ffffff;
}

.about-us-area .left-content .text .button .signature {
    display: inline-block;
    float: left;
    padding-left: 30px;
    margin-top: -15px;
}

.about-us-area .left-content .text .button .signature img {
    width: auto;
}

.about-us-area .right-content {}

.about-us-area .right-content .single-item {
    border: 1px solid #ebf8ff;
    margin-bottom: 30px;
    border-radius: 3px;
    display: block;
    padding: 30px 27px 22px;
}

.about-us-area .right-content .single-item .icon-holder {}

.about-us-area .right-content .single-item .icon-holder span:before {
    font-size: 50px;
    line-height: 50px;
}

.about-us-area .right-content .single-item .text {}

.about-us-area .right-content .single-item .text h3 {
    margin: 18px 0 15px;
    font-family: var(---font01);
    font-size: 17px;
    font-weight: 500;
}

.about-us-area .right-content .single-item .text p {
    margin: 0;
    font-family: var(---font01);
    font-size: 15px;
}

/* //////////////////////////////////////////////// */

.left-content {
    text-align: left;
}

.left-content .text p {
    text-align: left;
    font-family: var(---font01);
    font-size: 15px;
}

.single-item {
    display: flex;
    align-items: center;
    justify-content: center;
}

.single-item .text {
    text-align: left;
}

@media(max-width:768px){
    .about-us-area {
        background: #f7f7f7;
        padding: 50px 0 50px;
    }
    .about-us-area .right-content .single-item {
        border: 1px solid #e3e3e3;
    }
}

@media(max-width:430px){
    .single-item{
        display: none;
    }
}