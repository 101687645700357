.ServiceDetails-Section-1 {
    background: url("../Images/Banner/banner-02.avif");
    -webkit-background-size: cover;
    height: 80vh;
}

.ServiceDetails-Section-con {
    align-items: center;
    display: flex;
    height: 80vh;
}

.ServiceDetails-Section-Text {
    margin: 0 0 0 30px;
}

.ServiceDetails-Section-Text h1 {
    color: #fff;
    font-family: var(---font01);
    font-size: 30px;
    font-weight: 500;
    letter-spacing: 1px;
    text-align: left;
    text-transform: capitalize;
}

.ServiceDetails-Section-NavPage {
    align-items: center;
    display: flex;
    font-family: var(---SmallFont);
}

.ServiceDetails-Section-NavPage span {
    color: #fff;
    font-family: var(---Font1);
}

.ServiceDetails-Section-NavPage span svg {
    font-size: 17px;
    margin: 0 4px 5px 0;
}

.ServiceDetails-Section-NavPage h3 svg {
    color: #fff;
}

.ServiceDetails-Section-NavPage h2 {
    color: #fff;
    font-family: var(---Font1);
    font-size: 16px;
    font-weight: 400;
    margin: 0;
}

/* section 2  */

.Service-Detilas-Info-Con {
    /* border: 1px solid blue; */
    padding: 10vh 0px;
}

.Service-Detilas-Services-List-con {
    /* border: 1px solid var(---color01); */
    position: sticky;
    top: 0;
}

.Service-Detilas-Services-List-body .single-sidebar {
    display: block;
    overflow: hidden;
    margin-bottom: 40px;
}

.Service-Detilas-Services-List-body .single-sidebar .all-service {
    overflow: hidden;
    border: 1px solid #ebf8ff;
    border-bottom: none;
}

.Service-Detilas-Services-List-body .single-sidebar ul {
    padding: 0px;
}

.Service-Detilas-Services-List-body .single-sidebar .all-service li {
    position: relative;
    overflow: hidden;
    display: block;
    list-style: none !important;
    text-decoration: none;
    text-align: left;
}

.Service-Detilas-Services-List-body .single-sidebar .all-service li a {
    text-decoration: none;

}

.Service-Detilas-Services-List-body .single-sidebar .all-service li.active:before {
    opacity: 0;
    transition: all 500ms ease;
}

.Service-Detilas-Services-List-body .single-sidebar .all-service li:before {
    content: "";
    position: absolute;
    top: 0;
    right: 30px;
    width: 2px;
    height: 25px;
    background-color: var(---color01);
    border-radius: 10px;
    /* font-family: Flaticon; */
    /* font-size: 20px; */
    /* font-style: normal; */
    margin: 14px 0;
    color: #d3f0ff;
    transition: all 500ms ease;
}

.Service-Detilas-Services-List-body .single-sidebar .all-service li.active::after {
    opacity: 0;
    transition: all 500ms ease;
    background-color: #d3f0ff;
}

.Service-Detilas-Services-List-body .single-sidebar .all-service li.active::before {
    background-color: #d3f0ff;
}


.Service-Detilas-Services-List-body .single-sidebar .all-service li:after {
    content: "";
    position: absolute;
    top: 11px;
    right: 19px;
    width: 25px;
    height: 2px;
    background-color: var(---color01);
    border-radius: 10px;
    /* font-family: Flaticon; */
    /* font-size: 20px; */
    /* font-style: normal; */
    margin: 14px 0;
    color: #d3f0ff;
    transition: all 500ms ease;
}

.Service-Detilas-Services-List-body .single-sidebar .all-service li a:hover,
.Service-Detilas-Services-List-body .single-sidebar .all-service li.active a {
    background: #209fd6;
    color: #ffffff;
    transition: all 500ms ease;
}

.Service-Detilas-Services-List-body .single-sidebar .all-service li a {
    color: #949494;
    font-size: 16px;
    font-weight: 400;
    border-bottom: 1px solid #ebf8ff;
    display: block;
    padding: 14px 20px 12px;
    transition: all 500ms ease;
}


.Service-Detilas-Services-List-body .single-sidebar .all-service li.active::before,
.Service-Detilas-Services-List-body .single-sidebar .all-service li:hover::before {
    position: absolute;
    content: "";
    top: 11px;
    right: 19px;
    width: 25px;
    height: 2px;
    /* font-family: Flaticon; */
    /* font-size: 20px; */
    /* font-style: normal; */
    background-color: var(---color01);
    margin: 14px 0;
    color: #ffffff;
    transition: all 500ms ease;
}

.Service-Detilas-Services-Time-con {
    /* border: 1px solid blue; */
    text-align: left;
    margin-bottom: 40px;
}

.Service-Detilas-Services-Time-con h2 {
    font-family: var(---font01);
    font-size: 20px;
}

.Service-Details-time-con {
    /* border: 1px solid blue; */
    margin: 10px;
    border-top: 1px solid #ebf8ff;
    border-left: 1px solid #ebf8ff;
    border-right: 1px solid #ebf8ff;
}

.Service-Detils-Time-Detilas {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 10px;
    border-bottom: 1px solid #ebf8ff;
}

.Service-Detils-Time-Detilas h3 {
    font-family: var(---font01);
    font-size: 16px;
}


.Service-Detils-Time-Detilas-contact {
    display: flex;
    align-items: center;
    padding: 10px 10px;
    border-bottom: 1px solid #ebf8ff;
}

.Service-Detils-Time-Detilas-contact p a{
    text-decoration: none;
    color: gray;
}

.Service-Detils-Time-Detilas-contact h3 {}

.Service-Detils-Time-Detilas-contact svg {
    color: var(---color01);
    margin: 0px 10px 0px 10px;
}

.Service-Detils-Time-Detilas-contact p {
    margin: 0px;
    color: #949494;
}

/* ///////////////////////////////////////////////////////////////// */

.Service-details-info-con-main {
    /* border: 1px solid blue; */
}

.Service-Details-Heading-con {
    text-align: left;
}

.Service-Details-Heading-con h4 {
    position: relative;
    padding: 0px 0px 10px 0px;
    font-family: var(---font01);
    text-transform: capitalize;
}

.Service-Details-Heading-para {
    text-align: left;
}

.Service-Details-Heading-para p {
    font-family: var(---font01);
}

.Service-Details-image-BA-con {
    /* border: 1px solid blue; */
}

.Service-Details-image-con-main {
    /* border: 1px solid red; */
    height: 45vh;
}

.Service-Details-image-con-main img {
    height: 100%;
    width: 100%;
}

.Service-Details-image-name {
    margin: 20px 0px;
}

.Service-Details-image-name h5 {
    font-family: var(---font01);
}

/* ////////////////////////////////////////////////////////// */

.Service-deatils-Procedures-details-con {
    /* border: 1px solid blue; */
    margin-top: 50px;
}

.Service-deatils-Procedures-details {
    /* border: 1px solid red; */
}

.Service-Details-Procedures-card-body {
    /* border: 1px solid green; */
    display: flex;
    margin-top: 10px;
}

.Service-Details-Procedures-card-body-icon-con {}

.Service-Details-Procedures-card-body-icon {
    border: 1px solid blue;
    display: inline-block;
    margin: 0px 20px 0px 0px;
    padding: 15px;
    border-radius: 50px;
    transition: all .5s ease-in-out;
}

.Service-Details-Procedures-card-body-icon svg {
    font-size: 40px;
    color: var(---color01);
    transition: all .5s ease-in-out;
}

.Service-Details-Procedures-card-body-details {
    text-align: left;
}

.Service-Details-Procedures-card-body-details h3 {
    position: relative;
    font-family: var(---font01);
    font-size: 25px;
    text-transform: capitalize;
}

.Service-Details-Procedures-card-body-details p {
    font-family: var(---font01);
}

.Service-Details-Procedures-card-body:hover .Service-Details-Procedures-card-body-icon {
    background-color: var(---color01);
}

.Service-Details-Procedures-card-body:hover .Service-Details-Procedures-card-body-icon svg {
    color: white;
}

.Service-details-page-last-para {
    text-align: left;
}

.Service-details-page-last-para p {
    font-family: var(---font01);
}



@media(max-width:576px) {
    .ServiceDetails-Section-1 {
        height: 55vh;
    }

    .ServiceDetails-Section-con {
        align-items: center;
        display: flex;
        height: 60vh;
    }

    .Service-details-info-con-main {
        margin-top: 40px;
    }
}