.ProductList-Section-1 {
    background: url("../Images/Banner/banner-02.avif");
    -webkit-background-size: cover;
    height: 80vh;
}

.ProductList-Section-con {
    align-items: center;
    display: flex;
    height: 80vh;
}

.ProductList-Section-Text {
    margin: 0 0 0 30px;
}

.ProductList-Section-Text h1 {
    color: #fff;
    font-family: var(---font01);
    font-size: 30px;
    font-weight: 500;
    letter-spacing: 1px;
    text-align: left;
    text-transform: capitalize;
}

.ProductList-Section-NavPage {
    align-items: center;
    display: flex;
    font-family: var(---SmallFont);
}

.ProductList-Section-NavPage span {
    color: #fff;
    font-family: var(---font01);
}

.ProductList-Section-NavPage span svg {
    font-size: 17px;
    margin: 0 4px 5px 0;
}

.ProductList-Section-NavPage h3 svg {
    color: #fff;
}

.ProductList-Section-NavPage h2 {
    color: #fff;
    font-family: var(---Font1);
    font-size: 16px;
    font-weight: 400;
    margin: 0;
}

.Aboutus-banner-image-con{
    /* border: 1px solid blue; */

}

.Aboutus-banner-image{
    /* border: 1px solid red; */
    height: 240px;
    width: auto;
}

.Aboutus-banner-image img{
    height: 100%;
    width: 100%;

}